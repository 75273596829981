import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { gsap } from 'gsap';

@Component({
  selector: 'app-datenschutz',
  templateUrl: './datenschutz.component.html',
  styleUrls: ['./datenschutz.component.scss'],
})
export class DatenschutzComponent implements OnInit {
  @ViewChild('container')
  container: ElementRef<HTMLElement>;

  @ViewChild('card')
  card: ElementRef<HTMLElement>;

  constructor() {}

  ngOnInit(): void {}

  showDatenschutz() {
    gsap.set(this.container.nativeElement, { display: 'flex' });
    gsap.set(this.card.nativeElement, {
      y: 100,
      backgroundColor: 'rgba(255,255,255,0)',
      backdropFilter: 'blur(0px)',
    });
    gsap.set('.content', { opacity: 0 });
    gsap.to(this.card.nativeElement, {
      y: 0,
      backgroundColor: 'rgba(255,255,255,0.25)',
      backdropFilter: 'blur(15px)',
    });
    gsap.to('.content', { opacity: 1 });
  }
  hideDatenschutz() {
    gsap.to(this.card.nativeElement, {
      y: 100,
      backgroundColor: 'rgba(255,255,255,0)',
      backdropFilter: 'blur(0px)',
      onComplete: () => {
        gsap.set(this.container.nativeElement, { display: 'none' });
      },
    });
    gsap.to('.content', { opacity: 0 });
  }
}
