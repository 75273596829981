import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'

@Component({
    selector: 'app-video',
    templateUrl: './video.component.html',
    styleUrls: ['./video.component.scss'],
})
export class VideoComponent implements OnInit {
    public name?: string

    constructor(private route: ActivatedRoute) {
        this.route.params.subscribe((params) => {
            const { name } = params
            console.log(name)
            this.name = name
        })
    }

    ngOnInit(): void {}
}
