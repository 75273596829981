<div class="wrapper">
    <div class="welcome">
        <h1 #title class="title">
            <a
            ><span class="link-pre"></span><span class="link-first">Deltachi</span
            ><span class="link-second"> Designs</span><span class="link-post"></span
            ></a>
        </h1>
        <p #subtitle class="subtitle">
            <span class="hello">Hallo, ich bin </span>
            <span class="text"></span>
            <span class="cursor">_</span>
        </p>
        <div class="spacer">
            <div class="btn contact" (click)="showContractInformation()">
                <span class="btn-text">Jetzt Kontakt aufnehmen</span>
            </div>
            <p class="snack">
                Schreiben Sie mich gerne an, klicken Sie dafür einfach auf die
                eingeblendete Email-Adresse!
            </p>
        </div>
    </div>
    <div class="footer">
        <div (click)="datenschutz.showDatenschutz()" class="btn">Datenschutz</div>
        <div (click)="impressum.show()" class="btn">Impressum</div>
    </div>
</div>

<app-datenschutz #datenschutz></app-datenschutz>
<app-impressum #impressum></app-impressum>
