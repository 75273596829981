<div #container class="impressum-wrapper">
  <div #card class="impressum">
    <div (click)="hide()" class="content close">Ansicht schließen</div>
    <div class="content impressum-content">
      <h2>Impressum</h2>
      <p>
        Dominik Scheffler<br />
        Mendelssohnweg 9<br />
        33758 Schloß Holte
      </p>

      <p>E-Mail: <a href="mailto:info@deltachi.de">info@deltachi.de</a></p>

      <p>
        Wir nehmen nicht an einem Streitbeilegungsverfahren vor einer
        Verbraucherschlichtungsstelle teil.
      </p>

      <p>
        <a href="https://www.123recht.de/impressumsgenerator.asp"
          >Impressum erstellt mit dem Impressumsgenerator von 123recht.de -
          Rechtsberatung online.</a
        >
      </p>
    </div>
  </div>
</div>
