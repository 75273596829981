import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { HomepageComponent } from './pages/homepage/homepage.component'
import { VideoComponent } from './pages/video/video.component'

const routes: Routes = [
    {
        path: '',
        component: HomepageComponent,
    },
    {
        path: 'video/:name',
        component: VideoComponent,
    },
    {
        path: '**',
        component: HomepageComponent,
    },
]

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
