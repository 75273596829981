import { BrowserModule } from '@angular/platform-browser'
import { NgModule } from '@angular/core'

import { AppComponent } from './app.component'
import { DatenschutzComponent } from './components/datenschutz/datenschutz.component'
import { ImpressumComponent } from './components/impressum/impressum.component'
import { HomepageComponent } from './pages/homepage/homepage.component'
import { RouterModule } from '@angular/router'
import { AppRoutingModule } from './app-routing.module';
import { VideoComponent } from './pages/video/video.component'

@NgModule({
    declarations: [
        AppComponent,
        DatenschutzComponent,
        ImpressumComponent,
        HomepageComponent,
        VideoComponent,
    ],
    imports: [AppRoutingModule, BrowserModule, RouterModule],
    providers: [],
    bootstrap: [AppComponent],
})
export class AppModule {}
