import {
    AfterViewInit,
    Component,
    ElementRef,
    OnInit,
    ViewChild,
} from '@angular/core'
import { gsap } from 'gsap'
import { TextPlugin } from 'gsap/TextPlugin'
// @ts-ignore
import Timeline = gsap.core.Timeline
@Component({
    selector: 'app-homepage',
    templateUrl: './homepage.component.html',
    styleUrls: ['./homepage.component.scss'],
})
export class HomepageComponent implements AfterViewInit {
    @ViewChild('title')
    title: ElementRef<HTMLElement>

    @ViewChild('subtitle')
    subtitle: ElementRef<HTMLElement>

    private masterTL: Timeline

    private sentences = [
        'Dominik!',
        'UI/UX Designer',
        'Frontend Developer',
        '3D Designer',
    ]

    constructor() {
        gsap.registerPlugin(TextPlugin)
    }

    ngAfterViewInit(): void {
        const titleTL = gsap.timeline()
        titleTL.from(this.title.nativeElement, {
            x: 50,
            opacity: 0,
            delay: 0.2,
            duration: 1,
            ease: 'power2.out',
        })

        const cursor_tween = gsap.to('.cursor', {
            opacity: 0,
            ease: 'power2.inOut',
            repeat: -1,
        })

        this.masterTL = gsap.timeline({ repeat: -1 })

        this.sentences.forEach((words) => {
            const tl = gsap
                .timeline({ repeat: 1, yoyo: true, repeatDelay: 0.75 })
                .to('.text', { duration: 1.5, text: words })
            this.masterTL.add(tl)
        })
    }
    showContractInformation(): void {
        const contactTL = gsap
            .timeline()
            .to('.btn.contact', { opacity: 0, display: 'none', duration: 0.2 })
            .to('.link-second', { opacity: 0 })
            .set('.link-second', { opacity: 1, text: '' })

            .to('.link-pre', {
                text: 'info@',
            })
            .to('.link-second', {
                text: '.de',
                onComplete: () => {
                    this.title.nativeElement
                        .querySelector('a')
                        .setAttribute('href', 'mailto:info@deltachi.de')
                },
            })
            .to('.snack', { display: 'block', opacity: 1 })
    }
}
